<template>
  <main>
  <div class="contenu2">
  <div style="position:relative;">
     <!-- mobile -->
     <div class="logo mobile"><router-link to="/"><img  src="../assets/images/logo.png"></router-link></div>
     <img class="top-mobile mobile" src="../assets/images/top-vague4-mobile.jpg">
     <!-- commun -->
     <div class="top-contact"><img src="../assets/images/top-vide.png"></div>
  </div>
    <div class="contact">
      <p>
       Le jeu est désormais terminé
      </p>
    </div>
    </div>
    <img class="mobile" src="../assets/images/bottom-contact.jpg" width="100%">
  </main>
</template>
<script>
export default {
  name: 'Wait',
}
</script>
<style>


</style>